import { collection, getFirestore, addDoc, } from "firebase/firestore";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";


export default async function UploadBd(modalidade, area, titulo, chave, arquivo, nome, email, co1, co2, co3, co4, co5, dataAtual) {

  let c1 = "";
  let c2 = "";
  let c3 = "";
  let c4 = "";
  let c5 = "";

  if (co1 !== "" && co2 !== "") {
    c1 = co1 + ", ";
  } else if (co1 !== "" && co2 === "") {
    c1 = co1;
  };

  if (co2 !== "" && co3 !== "") {
    c2 = co2 + ", ";
  } else if (co2 !== "" && co3 === "") {
    c2 = co2;
  };

  if (co3 !== "" && co4 !== "") {
    c3 = co3 + ", ";
  } else if (co3 !== "" && co4 === "") {
    c3 = co3;
  };

  if (co4 !== "" && co5 !== "") {
    c4 = co4 + ", ";
  } else if (co4 !== "" && co5 === "") {
    c4 = co4;
  };

  if (co5 !== "") {
    c5 = co5;
  }

  const db = getFirestore();
  const docRef = await addDoc(collection(db, "Resumos"), {
    to: [email],
    message: {
      subject: 'OX25 - Recibimos el template de su trabajo',
      html: `<style>
            *{
               font-family: sans-serif;
               font-Size: 15px
            }
          </style>
          
          <img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/OX%2FCAPA%20EMAIL.png?alt=media&token=63c241a6-0bc5-43c5-8f93-fdd4078c27bb" width="100%"/>
          <p>Recibimos en el template del póster (en powerpoint)  de su trabajo: "`+ (titulo) + `"</p>

          <p>La comisión científica evaluará el template enviado y recibirá un correo electrónico confirmando si es correcto o si necesita corrección, hasta el día 06/05/2024.</p>
          
          <p>Cualquier duda estamos a disposición.<br/><br/></p>
          
          <p>Cordialmente,<br>
          Comisión Científica<br>
          Ortodoncia Experience 2025<br>
          III Congreso Internacional de Odontología sin Fronteras<br/></p>
          <div align="center">
            <img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---PRETO%26VERDE%20(1).png?alt=media&token=4f22b716-ec57-4c71-bcfb-de67ef56b94d" width="150">
          </div>`,
    },

    tipo: "Trabalho OX - Apresentação - 25",
    email: email,
    modalidade: modalidade,
    area: area,
    titulo: titulo,
    chave: chave,
    arquivo: arquivo,
    nome: nome,
    co1: co1,
    co2: co2,
    co3: co3,
    co4: co4,
    co5: co5,
    dataAtual: dataAtual,
    coFormatado: "" + (c1) + "" + (c2) + "" + (c3) + "" + (c4) + "" + (c5) + "",
    ox25: "Sim"
  });

  const url = 'https://ox-notificacao-slack.vercel.app/notificacao/ox25';

  const dados = {
    email: email,
    etapa: "2º Etapa - (Powerpoint)",
    nome_trabalho: titulo,
    nome: nome,
    dataEnvio: dataAtual,
    coautores: "" + (c1) + "" + (c2) + "" + (c3) + "" + (c4) + "" + (c5) + ""
  };

  try {
    const response = await axios.post(url, dados, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log('Resposta da API:', response.data);
  } catch (error) {
    console.error('Erro na requisição:', error.response?.data || error.message);
  }

  const docRef2 = await addDoc(collection(db, "Resumos"), {
    to: ["jesus.adrianasouza@gmail.com"],
    message: {
      subject: 'OX25 - Um trabalho foi enviado',
      html: `<style>
            *{
               font-family: sans-serif;
               font-Size: 15px
            }
          </style>
          
          <img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/OX%2FCAPA%20EMAIL.png?alt=media&token=63c241a6-0bc5-43c5-8f93-fdd4078c27bb" width="100%"/>
          <p>Hola Dra. Adriana, ¡saludos!</p>
          <p>Identificamos el recibimiento de un nuevo trabajo para nuestro evento Ortodoncia Experience 2025. 😊</p>
        
          <p>Para realizar la evaluación acceda al sistema académico, inicie sesión y haga clic en el campo envío de trabajo.</p>
          
          <p>link: <a href="https://academico.odontologiasinfronteras.com/">https://academico.odontologiasinfronteras.com/</a></p>
          
          <p>Un abrazo,<br>
          Equipo de soporte Odontología Sin Fronteras.</p>
          <div align="center">
            <img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---PRETO%26VERDE%20(1).png?alt=media&token=4f22b716-ec57-4c71-bcfb-de67ef56b94d" width="150">
          </div>`,
    },

    tipo: "Aviso trabalho",
    email: "jesus.adrianasouza@gmail.com",
  });

  const docRef3 = await addDoc(collection(db, "Resumos"), {
    to: ["ivannahuayta@gmail.com"],
    message: {
      subject: 'OX25 - Um trabalho foi enviado',
      html: `<style>
            *{
               font-family: sans-serif;
               font-Size: 15px
            }
          </style>
          
          <img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/OX%2FCAPA%20EMAIL.png?alt=media&token=63c241a6-0bc5-43c5-8f93-fdd4078c27bb" width="100%"/>
          <p>Hola Dra. Ivanna, ¡saludos!</p>
          <p>Identificamos el recibimiento de un nuevo trabajo para nuestro evento Ortodoncia Experience 2025. 😊</p>
        
          <p>Para realizar la evaluación acceda al sistema académico, inicie sesión y haga clic en el campo envío de trabajo.</p>
          
          <p>link: <a href="https://academico.odontologiasinfronteras.com/">https://academico.odontologiasinfronteras.com/</a></p>
          
          <p>Un abrazo,<br>
          Equipo de soporte Odontología Sin Fronteras.</p>
          <div align="center">
            <img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---PRETO%26VERDE%20(1).png?alt=media&token=4f22b716-ec57-4c71-bcfb-de67ef56b94d" width="150">
          </div>`,
    },

    tipo: "Aviso trabalho",
    email: "ivannahuayta@gmail.com",
  });

  AsyncStorage.setItem('Historico', "Realizado");
  return ("Concluido")
}